import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import StoryList from './components/StoryList';
import StoryDetail from './components/StoryDetail';
import About from './components/About';
import Acknowledgement from './components/Acknowledgement';
import Footer from './components/Footer'; 
import Contact from './components/Contact';
import './App.css';

// Move ShortLinkRedirect to top level
const ShortLinkRedirect = () => {
  const { shortCode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const resolveShortLink = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/${shortCode}/`
        );
        navigate(`/s/${response.data.story_id}`, { replace: true });
      } catch (err) {
        console.error('Short link resolution failed:', err);
        navigate('/', { replace: true });
      }
    };

    resolveShortLink();
  }, [shortCode, navigate]);

  return null;
};

function MainLayout() {
  const [stories, setStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [dividerPosition, setDividerPosition] = useState(0);
  const [shortLink, setShortLink] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const storiesPerPage = 20;
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Params changed:', params.storyId);
    const fetchStoriesAndSelect = async () => {
      try {
        console.log('--- Starting fetchStoriesAndSelect ---');
        console.log('Current stories length:', stories.length);
        console.log('URL storyId:', params.storyId);

        // Always fetch the full list first
        if (stories.length === 0) {
          console.log('Fetching initial story list...');
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/stories-with-comment-count/`
          );
          setStories(response.data);
          console.log('Initial stories set, count:', response.data.length);
        }

        // If we have a story ID in URL
        if (params.storyId) {
          console.log('Processing URL story ID:', params.storyId);
          const existingStory = stories.find(s => s.id.toString() === params.storyId);
          console.log('Existing story check:', existingStory ? 'found' : 'not found');
          
          if (existingStory) {
            console.log('Setting existing story:', existingStory.id);
            setSelectedStory(existingStory);
            const newDividerPos = window.innerWidth > 901 ? 70 : 100;
            console.log('Setting divider position:', newDividerPos);
            setDividerPosition(newDividerPos);
          } else {
            console.log('Fetching individual story...');
            const storyResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/api/stories/${params.storyId}/`
            );
            console.log('Individual story fetched:', storyResponse.data.id);
            setSelectedStory(storyResponse.data);
            setStories(prev => {
              console.log('Updating stories list with new story');
              return [...prev, storyResponse.data];
            });
            const newDividerPos = window.innerWidth > 901 ? 70 : 100;
            console.log('Setting divider position:', newDividerPos);
            setDividerPosition(newDividerPos);
          }
        }
      } catch (error) {
        console.error('Error loading stories:', error);
      }
    };

    fetchStoriesAndSelect();
  }, [params.storyId]);

  // Add this useEffect to handle initial page load with story ID
  useEffect(() => {
    console.log('--- Layout effect ---');
    console.log('Selected story:', selectedStory?.id);
    console.log('Divider position:', dividerPosition);
    
    if (params.storyId && selectedStory) {
      console.log('Attempting to trigger animation...');
      const detailElement = document.querySelector('.story-detail');
      console.log('Detail element exists:', !!detailElement);
      
      if (detailElement) {
        console.log('Original width:', detailElement.style.width);
        detailElement.style.width = '0%';
        console.log('Set temporary width to 0%');
        
        // Trigger reflow
        void detailElement.offsetWidth;
        
        console.log('Setting final width to:', `${dividerPosition}%`);
        detailElement.style.width = `${dividerPosition}%`;
      }
    }
  }, [selectedStory, params.storyId, dividerPosition]);

  const totalPages = Math.ceil(stories.length / storiesPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startDrag = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', stopDrag);

    e.target.addEventListener('dblclick', resetDividerPosition);
  };

  const resetDividerPosition = () => {
    setDividerPosition(70);
    document.documentElement.style.setProperty('--dividerPosition', '30%');
  };

  const onDrag = (e) => {
    const newDividerPosition = ((window.innerWidth - e.clientX) / window.innerWidth) * 100;
    if (newDividerPosition >= 0 && newDividerPosition <= 100) {
      setDividerPosition(newDividerPosition);
    }
  };

  const stopDrag = () => {
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
    
    // Remove the double-click event listener after dragging stops
    document.removeEventListener('dblclick', resetDividerPosition);
  };

  const closeStoryDetail = () => {
    setSelectedStory(null);
    setDividerPosition(0); // Reset divider position when closing story
    console.log('Story detail closed');
    console.log('Divider Position set to 100 on story close');
  };

  const [copySuccess, setCopySuccess] = useState(false);

  const handleShare = async (storyId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/generate-short-link/${storyId}/`);
      const link = response.data.short_link;
      setShortLink(link);
      await navigator.clipboard.writeText(link);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
      console.log('Link copied to clipboard:', link);
    } catch (error) {
      console.error('Error generating or copying short link:', error);
    }
  };

  const onSelectStory = (story) => {
    navigate(`/s/${story.id}`);
    setSelectedStory(story);
    setShortLink(''); // Reset short link when a new story is selected
    const newDividerPosition = window.innerWidth > 901 ? 70 : 100;
    setDividerPosition(newDividerPosition);
    console.log('Story selected:', story);
  };

  useEffect(() => {
    const paddingValue = dividerPosition === 100 ? '0px' : '20px';
    document.documentElement.style.setProperty('--story-list-padding', paddingValue);
    console.log('Divider Position updated:', dividerPosition);
    console.log('Padding set to:', paddingValue);
  }, [dividerPosition]);

  return (
    <div className={`app ${selectedStory ? 'story-selected' : ''}`}>
      <Header />
      <div className="content">
        <div 
          className="story-list" 
          style={{width: selectedStory ? `calc(100% - ${dividerPosition}%)` : '100%'}}
        >
          {stories.length === 0 ? (
            <p>Loading stories...</p>
          ) : (
            <StoryList
              stories={stories}
              onSelectStory={onSelectStory}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              storiesPerPage={storiesPerPage}
            />
          )}
        </div>
        {selectedStory && (
          <>
            <div
              className="divider"
              onMouseDown={startDrag}
              style={{ left: `calc(100% - ${dividerPosition}%)` }}
            />
            <div
              className="story-detail"
              style={{ width: `${dividerPosition}%`, position: 'relative' }}
            >
              <div className="button-area">
                <div className="action-buttons">
                  <a href={selectedStory.url} target="_blank" rel="noopener noreferrer">
                    <button className="read-story-button">Read Story</button>
                  </a>
                  <a href={`https://news.ycombinator.com/item?id=${selectedStory.hn_id}`} target="_blank" rel="noopener noreferrer">
                    <button className="read-comments-button">Read Comments</button>
                  </a>
                  <button 
                    onClick={() => handleShare(selectedStory.id)} 
                    className="share-button"
                    aria-label="Share"
                  >
                    <span className="material-symbols-outlined">share</span>
                    {copySuccess && <div className="copy-tooltip">Copied!</div>}
                    {shortLink && !copySuccess && <span className="share-link">{shortLink}</span>}
                  </button>
                </div>
                {/* {shortLink && <p>Shareable Link: <a href={shortLink}>{shortLink}</a></p>} */}
                <button className="close-button" onClick={closeStoryDetail}>
                  ✖
                  <span className="slide-text">Close Comments Summary Tab</span>
                </button>
              </div>
              <StoryDetail story={selectedStory} />
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

function AppContent() {
  return (
    <Routes>
      <Route path="/s/:storyId" element={<MainLayout />} />
      <Route path="/:shortCode" element={<ShortLinkRedirect />} />
      <Route path="/" element={<MainLayout />} />
      <Route path="/about" element={<About />} />
      <Route path="/acknowledgement" element={<Acknowledgement />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;