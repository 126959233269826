import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="app-header">
        <a href="/" className="home-link">
        <h1>
          {console.log('Current environment:', process.env.REACT_APP_ENVIRONMENT)}
          {process.env.REACT_APP_ENVIRONMENT === 'staging' 
            ? 'HackerComments | Staging' 
            : 'HackerComments'}
        </h1>
        </a>
        <div className="dropdown">
          <button onClick={toggleDropdown} className="dropdown-button">
            <i className="bi bi-three-dots-vertical icon-large"></i> {/* Bootstrap three dots vertical icon */}
          </button>
          {dropdownOpen && (
            <ul className="dropdown-menu">
              <li><a href="/About">About</a></li>
              <li><a href="/Acknowledgement">Acknowledgement</a></li>
              <li><a href="/Contact">Contact</a></li>
            </ul>
          )}
        </div>
    </header>
  );
};

export default Header;